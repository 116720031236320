import {Box, Typography} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {FileUploadComponent as FileUpload} from '@components/index'
import {DamageFormComponentProps} from '@templates/DamageCreation'
import {FileType} from '@utils/types/file-types'

import useStyles from './style.hook'

/**
 * Cost
 */
interface CostProps extends DamageFormComponentProps {
	notRequiredPlaceholderProp?: string
}

const Cost: React.FC<CostProps> = ({values, setFieldValue}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {t} = useTranslation(undefined, {useSuspense: false})

	const allowedFileTypes = FileType.PDF

	const handleChangeFile = (e: ChangeEvent<HTMLInputElement>): void =>
		e.target.files![0].type === allowedFileTypes &&
		setFieldValue!('quotationDocument', e.target.files![0])

	const handleDeleteFile = (): void => setFieldValue!('quotationDocument', null)

	return (
		<Box className={classes.root}>
			<Typography
				id={'damage-create-form-upload-document-title'}
				className={classes.title}
				variant={'h3'}
			>
				{t('DamageCreationCostFormTitle')}
			</Typography>
			<Typography
				id={'damage-create-form-upload-document-subtitle'}
				className={classes.subTitle}
				variant={'body1'}
			>
				{t('DamageCreationCostFormSubTitle')}
			</Typography>
			<FileUpload
				id={'damage-create-form-upload-document-upload'}
				allowedFileTypes={allowedFileTypes}
				uploadedFile={values.quotationDocument}
				handleChangeFile={handleChangeFile}
				handleFileDelete={handleDeleteFile}
			/>
		</Box>
	)
}

Cost.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
	values: PropTypes.any,
	setFieldValue: PropTypes.func,
}

export default Cost
