import {TFunction} from 'i18next'
import * as Yup from 'yup'

// Util imports
import {FileType} from '@utils/types/file-types'
import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const createDamageSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		damageImages: Yup.array()
			.min(
				1,
				(obj) => `${t(FormValidationErrorMessages.ARRAY_MIN)} (Min. ${obj.min})`
			)
			.required(t(FormValidationErrorMessages.REQUIRED))
			.test(
				'fileType',
				t(FormValidationErrorMessages.FILE_TYPE_UNSUPPORTED),
				(values) => {
					let results = []
					if (values) {
						results = values.map((value: any) =>
							[FileType.JPG, FileType.JPEG, FileType.PNG].includes(value.type)
						) as any
					}

					// @ts-ignore
					return !results.includes(false)
				}
			),
		description: Yup.string()
			.min(
				5,
				(obj) =>
					`${t(FormValidationErrorMessages.STRING_MIN)} (Min. ${obj.min})`
			)
			.required(t(FormValidationErrorMessages.REQUIRED)),
		quotationDocument: Yup.mixed()
			.required(t(FormValidationErrorMessages.REQUIRED))
			.test(
				'fileType',
				t(FormValidationErrorMessages.FILE_TYPE_UNSUPPORTED),
				(value: any) => value && value.type === FileType.PDF
			),
	})
