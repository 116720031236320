import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside DamageCreation functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	stepperContainer: {
		marginTop: theme.spacing(5 / 8),
		backgroundColor: '#edf2f8',
	},
	stepper: {
		backgroundColor: '#edf2f8',
		margin: 'auto',
		width: theme.spacing(500 / 8),
	},
	errorMessageBox: {
		margin: theme.spacing(16 / 8, 'auto'),
		width: 'fit-content',
	},
	formContainer: {
		marginTop: theme.spacing(56 / 8),
	},
	buttonContainer: {
		marginTop: theme.spacing(120 / 8),
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: theme.spacing(25 / 8),
	},
	ctaButton: {
		width: theme.spacing(210 / 8),
		padding: theme.spacing(14 / 8, 0),
		'&:nth-child(2)': {
			marginLeft: theme.spacing(30 / 8),
		},
	},
	backButton: {
		backgroundColor: `#f1f2f6`,
		color: `#524fa2`,
		'&:hover': {
			backgroundColor: 'rgba(241, 242, 246, 0.8)',
			borderColor: 'rgba(255, 255, 255, 1)',
		},
		'&:active': {
			backgroundColor: 'rgba(241, 242, 246, 0.7)',
			borderColor: 'rgba(255, 255, 255, 1)',
		},
	},
	placeholderContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	placeholder: {
		marginTop: theme.spacing(52 / 8),
		'& #damage-create-success-placeholder-header-text': {
			fontSize: 36,
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(2),
		},
	},
	bicyclesButton: {
		width: theme.spacing(210 / 8),
		padding: theme.spacing(14 / 8, 0),
		marginTop: theme.spacing(3),
	},
}))

export default useStyles
