import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside Cost functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(7),
	},
	title: {
		color: '#08080d',
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: theme.spacing(12 / 8),
	},
	subTitle: {
		color: '#7e7e80',
		fontSize: 16,
		marginBottom: theme.spacing(3),
	},
}))

export default useStyles
