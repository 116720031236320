import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside BicycleInfo functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(7),
	},
	input: {
		'&:not(:first-child)': {
			marginTop: theme.spacing(2),
		},
	},
}))

export default useStyles
