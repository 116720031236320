import {Typography, Box} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {
	FileUploadComponent as FileUpload,
	CustomTextInputComponent as CustomTextInput,
} from '@components/index'
import {DamageFormComponentProps} from '@templates/DamageCreation'
import {FileType} from '@utils/types/file-types'

import useStyles from './style.hook'

/**
 * Description
 */
interface DescriptionProps extends DamageFormComponentProps {
	notRequiredPlaceholderProp?: string
}

const Description: React.FC<DescriptionProps> = ({
	values,
	errors,
	touched,
	handleChange,
	handleBlur,
	setFieldValue,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {t} = useTranslation(undefined, {useSuspense: false})

	const allowedFileTypes = [FileType.JPG, FileType.JPEG, FileType.PNG]

	const handleChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
		if (
			e.target.files![0] &&
			allowedFileTypes.includes(e.target.files![0].type as FileType)
		) {
			setFieldValue!(
				'damageImages',
				values.damageImages
					? [...values.damageImages, e.target.files![0]]
					: [e.target.files![0]]
			)
		}
	}

	const handleRemoveImage = (index: number): void => {
		values.damageImages.splice(index, 1)
		if (values.damageImages.length === 0) {
			values.damageImages = null as any
		}

		setFieldValue!('damageImages', values.damageImages)
	}

	return (
		<Box className={classes.root}>
			<Typography variant={'body1'}>
				{t('DamageCreationDescriptionFormPictures')}
			</Typography>
			<FileUpload
				id={'damage-create-form-pictures-upload'}
				className={classes.upload}
				uploadedFile={null as any}
				allowedFileTypes={allowedFileTypes.join(', ')}
				handleChangeFile={handleChangeFile}
				handleFileDelete={(): void => {}}
				helperText={t('InsurancaCaseHelperText')}
				helperTextId={'damage-create-form-pictures-upload-helper-text'}
			/>
			{values.damageImages && (
				<>
					<Typography className={classes.previewText} variant={'body1'}>
						{t('DamageCreationDescriptionFormPreview')}
					</Typography>
					<Box className={classes.previewContainer}>
						{values.damageImages.map((file: File, index: number) => (
							<Box
								onClick={(): void => handleRemoveImage(index)}
								key={index}
								className={classes.previewItemContainer}
							>
								<img
									className={classes.previewItemImage}
									src={URL.createObjectURL(file)}
								/>
								<Box className={classes.previewItemOverlay}>
									<Box
										className={classes.previewItemOverlayIconWhiteBackground}
									/>
									<Box className={classes.previewItemOverlayIcon} />
								</Box>
							</Box>
						))}
					</Box>
				</>
			)}
			<CustomTextInput
				id={'damage-create-form-description-input'}
				label={t('DamageCreationDescriptionFormDescription')}
				error={(errors.description && touched.description) as boolean}
				helperText={(touched.description && errors.description) as string}
				helperTextId={errors.description && 'description-helper-text'}
				propsToDelegate={{
					placeholder: t('DamageCreationDescriptionFormDescriptionPlaceholder'),
					name: 'description',
					type: 'text',
					value: values.description,
					error: (errors.description && touched.description) as boolean,
					onChange: handleChange,
					onBlur: handleBlur,
					fullWidth: true,
				}}
			/>
		</Box>
	)
}

Description.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
	values: PropTypes.any,
	errors: PropTypes.any,
	touched: PropTypes.any,
	handleChange: PropTypes.any,
	handleBlur: PropTypes.any,
	setFieldValue: PropTypes.func,
}

export default Description
