import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside Description functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(7),
	},
	upload: {
		marginBottom: theme.spacing(5),
	},
	previewText: {
		color: '#7e7e80',
	},
	previewContainer: {
		display: 'flex',
		marginBottom: theme.spacing(2),
		overflow: 'auto',
		whiteSpace: 'nowrap',
	},
	previewItemContainer: {
		height: theme.spacing(8),
		width: 'fit-content',
		marginRight: theme.spacing(1),
		position: 'relative',
		cursor: 'pointer',
		'&:hover $previewItemOverlay': {
			display: 'block',
		},
	},
	previewItemImage: {
		height: 'inherit',
	},
	previewItemOverlay: {
		display: 'none',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.32)',
	},
	previewItemOverlayIcon: {
		height: theme.spacing(17 / 8),
		width: theme.spacing(2),
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: 'url(/images/trash-icon.svg)',
		backgroundRepeat: 'no-repeat',
	},
	previewItemOverlayIconWhiteBackground: {
		position: 'absolute',
		height: theme.spacing(4),
		width: theme.spacing(4),
		backgroundColor: '#FFF',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 30,
	},
}))

export default useStyles
