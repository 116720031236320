import {Box} from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomTextInputComponent as CustomTextInput} from '@components/index'
import {DamageFormComponentProps} from '@templates/DamageCreation'
import {NO_GOOGLE_TRANSLATE} from '@utils/constants/util-constants'

import useStyles from './style.hook'

/**
 * BicycleInfo
 */
interface BicycleInfoProps extends DamageFormComponentProps {
	notRequiredPlaceholderProp?: string
}

const BicycleInfo: React.FC<BicycleInfoProps> = ({values, touched, errors}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {t} = useTranslation(undefined, {useSuspense: false})

	return (
		<Box className={classes.root}>
			<CustomTextInput
				id={'damage-create-form-cyclist-name-input'}
				label={t('DamageCreationBicycleInfoFormCyclistName')}
				className={classNames(classes.input, NO_GOOGLE_TRANSLATE)}
				error={(errors.cyclistName && touched.cyclistName) as boolean}
				helperText={(touched.cyclistName && errors.cyclistName) as string}
				helperTextId={errors.cyclistName && 'cyclist-name-helper-text'}
				propsToDelegate={{
					name: 'cyclistName',
					type: 'text',
					value: values.cyclistName,
					error: (errors.cyclistName && touched.cyclistName) as boolean,
					fullWidth: true,
					disabled: true,
				}}
			/>
			<CustomTextInput
				id={'damage-create-form-sales-order-number-input'}
				label={t('DamageCreationBicycleInfoFormSalesOrderNumber')}
				className={classNames(classes.input, NO_GOOGLE_TRANSLATE)}
				error={(errors.salesOrderNumber && touched.salesOrderNumber) as boolean}
				helperText={
					(touched.salesOrderNumber && errors.salesOrderNumber) as string
				}
				helperTextId={
					errors.salesOrderNumber && 'sales-order-number-helper-text'
				}
				propsToDelegate={{
					name: 'salesOderNumber',
					type: 'text',
					value: values.salesOrderNumber,
					error: (errors.salesOrderNumber &&
						touched.salesOrderNumber) as boolean,
					fullWidth: true,
					disabled: true,
				}}
			/>
			<CustomTextInput
				id={'damage-create-form-bicycle-info-input'}
				label={t('DamageCreationBicycleInfoFormBicycleInfo')}
				className={classes.input}
				error={(errors.bicycleInfo && touched.bicycleInfo) as boolean}
				helperText={(touched.bicycleInfo && errors.bicycleInfo) as string}
				helperTextId={errors.bicycleInfo && 'bicycle-info-helper-text'}
				propsToDelegate={{
					name: 'bicycleInfo',
					type: 'text',
					value: values.bicycleInfo,
					error: (errors.bicycleInfo && touched.bicycleInfo) as boolean,
					fullWidth: true,
					disabled: true,
				}}
			/>
		</Box>
	)
}

BicycleInfo.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
	values: PropTypes.any,
	touched: PropTypes.any,
	errors: PropTypes.any,
}

export default BicycleInfo
