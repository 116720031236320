import {Box, Stepper, Step, StepLabel, Grid} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import {FormikErrors, FormikTouched} from 'formik/dist/types'
import PropTypes from 'prop-types'
import React, {ReactNode, useState, useEffect, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'

import {
	TemplateContainerComponent as TemplateContainer,
	CyclisHeaderComponent as CyclisHeader,
	CustomButtonComponent as CustomButton,
	CustomPlaceholderComponent as CustomPlaceholder,
	ErrorMessageBoxComponent as ErrorMessageBox,
} from '@components/index'
import NotFoundPage from '@pages/404'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {InsuranceSuccessIconSvg} from '@svg/index'
import {getBicycleDetail} from '@templates/BicycleDetail/bicycle-detail-slice'
import BicycleInfo from '@templates/DamageCreation/FormScreens/BicycleInfo'
import Cost from '@templates/DamageCreation/FormScreens/Cost'
import Description from '@templates/DamageCreation/FormScreens/Description'
import {
	DamageCreationForm,
	createDamage,
	resetForm,
} from '@templates/DamageCreation/create-damage-slice'
import {LanguageContext} from '@utils/context'
import {CommonProps} from '@utils/types'

import {createDamageSchema} from './create-damage-validation'
import useStyles from './style.hook'

export interface DamageFormComponentProps {
	touched: FormikTouched<DamageCreationForm>
	errors: FormikErrors<DamageCreationForm>
	values: DamageCreationForm
	handleBlur: (eventOrString: any) => void | ((e: any) => void)
	handleChange: (
		eventOrPath: string | React.ChangeEvent<any>
	) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
	setFieldValue?: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => any
}

/**
 * DamageCreation
 */
interface DamageCreationProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

const DamageCreation: React.FC<DamageCreationProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()

	const [activeStep, setActiveStep] = useState<number>(0)
	const [values, setValues] = useState<DamageCreationForm>({
		cyclistName: '',
		salesOrderNumber: '',
		bicycleInfo: '',
		damageImages: null as any,
		description: '',
		quotationDocument: null as any,
	})

	const {activeLanguage} = useContext(LanguageContext)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const bicycle = useSelector((state: RootState) => state.bicycleDetail.bicycle)
	const bicycleError = useSelector(
		(state: RootState) => state.bicycleDetail.error
	)
	const {success, loading} = useSelector(
		(state: RootState) => state.createDamage
	)

	const {t} = useTranslation(undefined, {useSuspense: false})

	useEffect(() => {
		dispatch(resetForm())
	}, [])

	useEffect(() => {
		const {search} = location!
		const params = new URLSearchParams(search)
		const bicycleId = parseInt(params.get('id')!, 10)

		if (bicycleId) {
			dispatch(getBicycleDetail(bicycleId))
		}
	}, [location])

	const steps = [
		{
			keys: ['cyclistName', 'salesOrderNumber', 'bicycleInfo'],
			label: t('DamageCreationPageStepBicycleInfo'),
		},
		{
			keys: ['damageImages', 'description'],
			label: t('DamageCreationPageStepDescription'),
		},
		{keys: ['quotationDocument'], label: t('DamageCreationPageStepCost')},
	]

	const stepContent = (props?: DamageFormComponentProps): React.ReactNode[] => [
		<BicycleInfo key={0} {...(props as any)} />,
		<Description key={1} {...(props as any)} />,
		<Cost key={2} {...(props as any)} />,
	]

	const mergeValuesWithBicycle = (
		values: DamageCreationForm
	): DamageCreationForm => {
		return {
			...values,
			cyclistName: `${bicycle.cyclistFirstName} ${bicycle.cyclistLastName}`,
			salesOrderNumber: bicycle.salesOrderNumber,
			bicycleInfo: `${bicycle.brand} ${bicycle.model}`,
		}
	}

	const goToPreviousStep = (): void => setActiveStep(activeStep - 1)

	const goToNextStep = (): void => setActiveStep(activeStep + 1)

	const goToBicycles = (): void => {
		dispatch(
			navigate(`/${activeLanguage}/app/bicycles/detail?id=${bicycle.bicycleId}`)
		)
	}

	const submitDamageForm = async (
		values: DamageCreationForm
	): Promise<void> => {
		dispatch(createDamage(values, bicycle.bicycleId.toString()))
	}

	useEffect(() => {
		if (bicycle) {
			setValues(mergeValuesWithBicycle(values))
		}
	}, [bicycle])

	return bicycleError === 'StandardErrorNotFound' ? (
		<NotFoundPage />
	) : (
		<TemplateContainer
			id={'create-damage-container'}
			className={classNames({
				[classes.root]: success,
			})}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			{success ? (
				<Box className={classes.placeholderContainer}>
					<CustomPlaceholder
						id={'damage-create-success'}
						className={classes.placeholder}
						icon={<InsuranceSuccessIconSvg />}
						headerText={t('DamageCreationPlaceholderTitle')}
						subTitle={t('DamageCreationPlaceholderSubTitle')}
					/>
					<CustomButton
						id={'damage-create-success-placeholder-button'}
						className={classes.bicyclesButton}
						text={t('DamageCreationPlaceholderButton')}
						propsToDelegate={{
							onClick: goToBicycles,
						}}
					/>
				</Box>
			) : (
				<>
					<Box
						id={'damage-create-stepper-container'}
						className={classes.stepperContainer}
					>
						<Stepper
							id={'damage-create-stepper'}
							className={classes.stepper}
							activeStep={activeStep}
						>
							{steps.map((step, index) => (
								<Step id={`damage-create-step-${index}`} key={index}>
									<StepLabel id={`damage-create-step-label-${index}`}>
										{step.label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					{bicycleError && (
						<ErrorMessageBox
							className={classes.errorMessageBox}
							errorMessage={t('DamageCreationBicycleDetailError')}
						/>
					)}
					<Formik
						initialValues={values}
						onSubmit={submitDamageForm}
						validationSchema={createDamageSchema(t)}
						enableReinitialize={true}
					>
						{({
							values,
							errors,
							touched,
							isValid,
							handleChange,
							handleBlur,
							handleSubmit,
							submitForm,
							setFieldValue,
							validateForm,
						}): ReactNode => {
							// eslint-disable-next-line react-hooks/rules-of-hooks
							useEffect(() => {
								validateForm()
							}, [values])

							const [nextStepActionDisabled, setNextStepActionDisabled] =
								// eslint-disable-next-line react-hooks/rules-of-hooks
								useState<boolean>(false)

							// eslint-disable-next-line react-hooks/rules-of-hooks
							useEffect(() => {
								setNextStepActionDisabled(
									steps[activeStep].keys.some(
										(key) => (errors as any)[key] !== undefined
									)
								)
							}, [errors, activeStep])

							return (
								<form id={'damage-create-form'} onSubmit={handleSubmit}>
									<Grid spacing={8} container>
										<Grid item xs={activeStep === 0 ? 4 : 3} />
										<Grid item xs={activeStep === 0 ? 4 : 6}>
											<Box
												id={'damage-create-form-container'}
												className={classes.formContainer}
											>
												{
													stepContent({
														values,
														touched,
														errors,
														handleBlur,
														handleChange,
														setFieldValue,
													})[activeStep]
												}
											</Box>
											<Box
												id={'damage-create-form-button-container'}
												className={classNames(classes.buttonContainer)}
											>
												{activeStep !== 0 && (
													<CustomButton
														id={'damage-create-form-back-button'}
														className={classNames(
															classes.ctaButton,
															classes.backButton
														)}
														text={t('DamageCreationBackButton')}
														propsToDelegate={{onClick: goToPreviousStep}}
													/>
												)}
												{activeStep === stepContent().length - 1 ? (
													<CustomButton
														id={'damage-create-form-submit-button'}
														className={classes.ctaButton}
														text={t('DamageCreationSubmitButton')}
														propsToDelegate={{
															onClick: submitForm,
															disabled: !isValid || loading,
														}}
													/>
												) : (
													<CustomButton
														id={'damage-create-form-next-button'}
														className={classes.ctaButton}
														text={t('DamageCreationNextButton')}
														propsToDelegate={{
															onClick: goToNextStep,
															disabled: nextStepActionDisabled,
														}}
													/>
												)}
											</Box>
										</Grid>
										<Grid item xs={activeStep === 0 ? 4 : 3} />
									</Grid>
								</form>
							)
						}}
					</Formik>
				</>
			)}
		</TemplateContainer>
	)
}

DamageCreation.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
	location: PropTypes.any,
}

export default DamageCreation
